import Head from 'next/head';

export default function FaviconHead({ tenant }: { tenant: string }) {
  const iconPath = () => (tenant === 'asiadirect' ? '/images/asiadirect' : '');

  return (
    <Head>
      <link
        rel="icon"
        sizes="64x64"
        href={`${iconPath()}/favicon.ico`}
        type="image/x-icon"
      />
      <link
        rel="apple-touch-icon"
        sizes="57x57"
        href={`${iconPath()}/apple-touch-icon-57x57.png`}
        type="image/png"
      />
      <link
        rel="apple-touch-icon"
        sizes="60x60"
        href={`${iconPath()}/apple-touch-icon-60x60.png`}
        type="image/png"
      />
      <link
        rel="apple-touch-icon"
        sizes="72x72"
        href={`${iconPath()}/apple-touch-icon-72x72.png`}
        type="image/png"
      />
      <link
        rel="apple-touch-icon"
        sizes="76x76"
        href={`${iconPath()}/apple-touch-icon-76x76.png`}
        type="image/png"
      />
      <link
        rel="apple-touch-icon"
        sizes="114x114"
        href={`${iconPath()}/apple-touch-icon-114x114.png`}
        type="image/png"
      />
      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href={`${iconPath()}/apple-touch-icon-120x120.png`}
        type="image/png"
      />
      <link
        rel="apple-touch-icon"
        sizes="144x144"
        href={`${iconPath()}/apple-touch-icon-144x144.png`}
        type="image/png"
      />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href={`${iconPath()}/apple-touch-icon-152x152.png`}
        type="image/png"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={`${iconPath()}/apple-touch-icon-180x180.png`}
        type="image/png"
      />
      <link
        rel="icon"
        type="image/png"
        href={`${iconPath()}/favicon-32x32.png`}
        sizes="32x32"
      />
      <link
        rel="icon"
        type="image/png"
        href={`${iconPath()}/android-chrome-192x192.png`}
        sizes="192x192"
      />
      <link
        rel="icon"
        type="image/png"
        href={`${iconPath()}/favicon-96x96.png`}
        sizes="96x96"
      />
      <link
        rel="icon"
        type="image/png"
        href={`${iconPath()}/favicon-16x16.png`}
        sizes="16x16"
      />
      <link
        rel="manifest"
        href={
          tenant === 'asiadirect'
            ? '/manifest-asiadirect.json'
            : '/manifest.json'
        }
      />
    </Head>
  );
}
