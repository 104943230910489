import Image from 'next/image';
import React from 'react';
import { ArrowDown } from '@alphafounders/icons';
import useLgtCookie from 'hooks/useLgtCookie';
import Heart from '@public/icons/heart.svg';
import Bullet from '@public/icons/bullet.svg';
import LineLogo from '@public/icons/line.svg';
import MessengerLogo from '@public/icons/messenger-chat.svg';
import { ContactButton, PhoneButton } from '@alphafounders/ui';
import { baseUrl } from 'lib/constants';
import clsx from 'clsx';
import Link from 'components/common/Link';

interface Navigation {
  text: string;
  url: string;
  subcategory: Navigation[];
}

interface Footer {
  content: string;
  links: {
    id: number;
    title: string;
    link: string;
  }[];
}

interface Contact {
  phone: string;
  openingHours: string;
  lineUrl: string;
  messengerUrl: string;
}

interface LayoutComponentProps {
  children: React.ReactNode;
  tenant?: string;
  navigations: Navigation[];
  footer: Footer;
  contact: Contact;
  locale?: string;
}

export default function LayoutComponent({
  children,
  tenant,
  navigations,
  footer,
  contact,
  locale,
}: LayoutComponentProps) {
  useLgtCookie();

  return (
    <>
      <header className="sticky z-50 bg-white w-full top-0 shadow">
        <div className="flex flex-row flex-wrap items-center px-5 py-3 gap-3 mx-auto max-w-7xl peer/hamburger group/hamburger">
          <label
            htmlFor="menu-toggle"
            className="relative w-6 h-6 cursor-pointer flex flex-col justify-center md:hidden"
          >
            <input type="checkbox" id="menu-toggle" className="hidden" />
            <span className="block h-[3px] bg-primary rounded-sm transition-transform duration-300 ease-in-out group-has-[:checked]/hamburger:rotate-45 group-has-[:checked]/hamburger:translate-y-[7px]" />
            <span className="block h-[3px] bg-primary rounded-sm transition-opacity duration-300 ease-in-out my-1 group-has-[:checked]/hamburger:opacity-0" />
            <span className="block h-[3px] bg-primary rounded-sm transition-transform duration-300 ease-in-out group-has-[:checked]/hamburger:-rotate-45 group-has-[:checked]/hamburger:-translate-y-[7px]" />
          </label>
          <Link href="/">
            <div className="flex flex-col items-end md:flex-row md:items-center md:gap-2 md:divide-x">
              <Image
                src={
                  tenant === 'asiadirect'
                    ? '/asiadirect-logo.png'
                    : '/rabbit-care-logo.png'
                }
                alt="Rabbit Care Logo"
                quality={100}
                width={354}
                height={56}
                priority
                sizes="100vw"
                style={{
                  width: '100%',
                  maxWidth: '180px',
                  minWidth: '135px',
                  height: 'auto',
                }}
              />
              <p className="uppercase leading-tight text-xs text-primary text-nowrap md:text-sm md:p-2">
                Complete Care
              </p>
            </div>
          </Link>

          <div className="ml-auto space-x-1 flex items-center">
            <div className="space-x-1 largerThan395:space-x-1.5 lg:space-x-2.5 flex items-center">
              {contact.phone && (
                <PhoneButton
                  number={contact.phone}
                  subText={contact.openingHours}
                  className="bg-primaryColor border-primaryColor md:fill-primaryColor md:text-primaryColor sm:hover:border-primaryColor"
                />
              )}
              {contact.lineUrl && (
                <ContactButton
                  className={clsx(
                    'bg-primaryColor border-primaryColor',
                    tenant === 'asiadirect' &&
                      'hover:md:border-secondaryColor hover:md:bg-secondaryColor'
                  )}
                  href={contact.lineUrl}
                  imagePath={`${baseUrl}/icons/line.svg`}
                  altText="Line"
                  ariaLabel="LINE Add Friend"
                  icon={<LineLogo />}
                />
              )}
              {contact.messengerUrl && (
                <ContactButton
                  className={clsx(
                    'bg-primaryColor border-primaryColor',
                    tenant === 'asiadirect' &&
                      'hover:md:border-secondaryColor hover:md:bg-secondaryColor'
                  )}
                  href={contact.messengerUrl}
                  imagePath={`${baseUrl}/icons/messenger.svg`}
                  altText="Facebook"
                  ariaLabel="MSN Messenger"
                  icon={<MessengerLogo />}
                />
              )}
            </div>
          </div>
        </div>
        {/* Mobile nav */}
        <div className="sidenav z-50 absolute bg-white h-[94vh] w-full border duration-500 -translate-x-full peer-has-[:checked]/hamburger:translate-x-0 overflow-y-auto">
          <div className="flex flex-col text-navTextColor font-bold">
            {navigations.map((menu, index) => {
              const hasSubcategory = menu.subcategory.length > 0;
              return (
                <div key={index} className="border-b group/accordian">
                  <label htmlFor={`nav-accordian${index}`}>
                    <input
                      id={`nav-accordian${index}`}
                      type="checkbox"
                      className="hidden"
                    />
                    {hasSubcategory ? (
                      <div className="relative z-10 bg-white flex flex-row justify-between items-center px-3 py-4 ">
                        <span>{menu.text}</span>
                        <ArrowDown className="fill-navTextColor transform transition duration-300 group-has-[:checked]/accordian:rotate-180" />
                      </div>
                    ) : (
                      <Link href={menu.url} passHref locale={locale}>
                        <div className="relative z-10 bg-white flex flex-row justify-between items-center px-3 py-4 ">
                          <span>{menu.text}</span>
                        </div>
                      </Link>
                    )}
                  </label>
                  {hasSubcategory && (
                    <div className="grid grid-rows-[0fr] group-has-[:checked]/accordian:grid-rows-[1fr] transition-[grid-template-rows] duration-300">
                      <div className="overflow-hidden">
                        {menu.subcategory.map((sub, index) => (
                          <Link
                            key={index}
                            href={sub.url}
                            passHref
                            locale={locale}
                          >
                            <div className="px-3 py-4 bg-accordionPanelBg border-b">
                              {sub.text}
                            </div>
                          </Link>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        {/* Desktop nav */}
        <div className="hidden md:flex flex-row flex-wrap w-full bg-white justify-center .overflow-hidden">
          {navigations.map((menu, index) => {
            const hasSubcategory = menu.subcategory.length > 0;
            return (
              <div key={index} className="group/navbar relative">
                {hasSubcategory ? (
                  <>
                    <div className="hover:bg-accordionPanelBg text-navTextColor text-sm font-bold py-3">
                      <div className="border-l group-last/navbar:border-r border-grayColor border-dashed h-8 flex items-center px-7 gap-3">
                        {menu.text}
                        {hasSubcategory && (
                          <ArrowDown className="fill-navTextColor transform transition duration-300 group-hover/navbar:rotate-180" />
                        )}
                      </div>
                    </div>
                    <div className="absolute w-64 bg-white rounded-b-2xl overflow-hidden border border-navBarBorder shadow-lg shadow-[0_10px_20px_rgba(58, 111, 232, 0.20)] z-50 hidden group-hover/navbar:block group-last/navbar:right-0">
                      {menu.subcategory.map((subMenuItem, index) => (
                        <div key={index} className="group/dropdown">
                          <Link href={subMenuItem.url} passHref locale={locale}>
                            <div className="border-b group-last/dropdown:border-0 border-grayColor border-dashed hover:bg-accordionPanelBg text-navTextColor text-sm py-3">
                              <div className="h-7 flex items-center px-4 gap-3">
                                {tenant === 'asiadirect' ? (
                                  <Bullet className="fill-navTextColor w-[0.875rem] h-[0.875rem] mt-[0.3125rem]" />
                                ) : (
                                  <Heart className="fill-navTextColor w-[0.9575rem] h-[0.8125rem]" />
                                )}
                                {subMenuItem.text}
                              </div>
                            </div>
                          </Link>
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <Link href={menu.url} passHref locale={locale}>
                    <div className="hover:bg-accordionPanelBg text-navTextColor text-sm font-bold py-3">
                      <div className="border-l group-last/navbar:border-r border-grayColor border-dashed h-8 flex items-center px-7 gap-3">
                        {menu.text}
                        {hasSubcategory && (
                          <ArrowDown className="fill-navTextColor transform transition duration-300 group-hover/navbar:rotate-180" />
                        )}
                      </div>
                    </div>
                  </Link>
                )}
              </div>
            );
          })}
        </div>
      </header>
      <main className="min-h-screen">{children}</main>

      {/* Footer */}
      <div className="bg-footerBg relative w-full">
        <div className="max-w-7xl w-full mx-auto py-12 px-6">
          <div className="flex flex-col-reverse items-start gap-8 md:flex-row">
            <Image
              src="https://storage.googleapis.com/round-fold/footer_logo_rabbitcare_5b679073c6/footer_logo_rabbitcare_5b679073c6.svg"
              width={95}
              height={34.7}
              alt="Rabbit Care Footer Logo"
              quality={50}
            />
            <div className="flex flex-col w-full gap-1 md:flex-row md:gap-4">
              {footer.links?.map((link) => (
                <Link key={link.id} href={link.link} passHref locale={locale}>
                  <div className="py-4 border-b border-dashed border-footerBorderLink md:border-0">
                    <div className="flex flex-row items-center justify-between">
                      <span className="text-sm text-footerLink">
                        {link.title}
                      </span>
                      <ArrowDown className="fill-footerLink -rotate-90 md:hidden" />
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>

          <div className="text-footerText text-sm mt-5">
            <p className="whitespace-pre-line">{footer.content}</p>

            <div className="flex flex-col gap-2 mt-2 md:flex-row">
              <span>Rabbitcare Site International: </span>
              <ul className="flex flex-row gap-2 flex-wrap">
                <li className="hover:underline">
                  <Link href="/" locale="th">
                    Thailand
                  </Link>
                </li>
                <li className="hover:underline">
                  <Link href="/" locale="vi">
                    Vietnam
                  </Link>
                </li>
                <li className="hover:underline">
                  <Link href="/" locale="en-PH">
                    Philippine
                  </Link>
                </li>
                <li className="hover:underline">
                  <Link href="/" locale="en-MY">
                    Malaysia
                  </Link>
                </li>
                <li className="hover:underline">
                  <Link href="/" locale="en-SG">
                    Singapore
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
